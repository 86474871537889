import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import Copy, { Bold, ListItem, OrderedList, UnorderedList } from '@nib-components/copy';

import Layout from '../../components/Layout';
import metrics from '../../metrics';

import LifeLivingInsuranceSection, {
  LifeLivingInsuranceSectionProps,
} from '../../page-sections/life-living-insurance-section';
import { ContentHeroPanelProps } from '../../components/ContentHeroPanel';
import { NavContentProps, NavLinkProps } from '../../components/NavContent';
import { ImportantInformationBannerProps } from '../../components/ImportantInformationBanner';
import Link from '@nib-components/link';
import { AddSystemIcon, ExternalLinkSystemIcon } from '@nib/icons';
import { Box, Section, Stack } from '@nib/layout';
import Heading from '@nib-components/heading';
import Accordion from '@nib-components/accordion';
import KiwibankImage from '../../img/life-and-living/kiwibank_white.svg';
import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import LifeAndLivingOfferImage from '../../img/life-and-living/life-living-insurance-discount.svg';
import { colorTrueGreen } from '@nib-components/theme';
import { urlConstants } from '../../constructs/constants';

const title = 'Life & Living Insurance For Kiwibank Customers';
const description =
  'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.';

const contentHeroData: ContentHeroPanelProps = {
  title: (
    <>
      Proud partners with Kiwibank. <span style={{ color: '#82E578' }}>Since 2022.</span>
    </>
  ),
  subtextLeft: 'LIFE INSURANCE',
  subtextRight: 'LIVING INSURANCE',
  description: (
    <>Plan for peace of mind and protect those who matter most with nib Life & Living Insurance.</>
  ),
  imageMobile: KiwibankImage,
  imageDesktop: KiwibankImage,
  imageAlt: 'Hero Image',
  type: 'banner',
  imageContain: true,
  backgroundColor: '#000000',
};

const navigationLinks: NavLinkProps[] = [
  {
    text: 'About Life & Living insurance',
    isActive: false,
    href: urlConstants.aboutLifeLivingInsurance,
  },
  {
    text: 'Special offers',
    isActive: false,
    href: urlConstants.currentOfferLifeLivingInsurance,
  },
  {
    text: 'Why get Life & Living insurance',
    isActive: false,
    href: urlConstants.whyLifeLivingInsuranceCoverIsImportant,
  },
  {
    text: 'Estimator Tool',
    isActive: false,
    href: urlConstants.estimateYourCoverageLifeLivingInsurance,
  },
  {
    text: 'For homeowners',
    isActive: true,
    href: urlConstants.lifeLivingHomeowners,
  },
  { text: 'How to apply', isActive: false, href: urlConstants.howToApplyLifeLivingInsurance },
];

const contentData = {
  title: 'Get $300 off nib Life & Living Insurance*',
  description: (
    <Copy>
      A great game plan protects what matters most. And life is no different. 
      <br />
      <br />
      With nib Life & Living insurance, you can protect your loved ones by protecting yourself.
      <br />
      <br />
      For a limited time, get $300 off new Life & Living insurance policies, after paying for your
      first month or more. Ends 30 June 2025. <Link href="#offer-terms">T&Cs apply.</Link>
    </Copy>
  ),
  buttonText: 'Get more info',
  buttonHref: urlConstants.currentOfferLifeLivingInsurance,
  image: LifeAndLivingOfferImage,
  imageAlt: 'Life and Living Offer',
};

const infoContentBlockData = {
  title: 'Protect Your Future',
  description: (
    <>
      Life insurance & Living insurance can safeguard your financial stability when life takes an
      unexpected turn. Whether you're unable to work due to illness, or face a serious health
      condition, this cover can help with your mortgage repayments and any necessary home
      modifications, like installing safety rails or ramps.
    </>
  ),
  boldText:
    'Plan for peace of mind and protect those who matter most with nib Life & Living Insurance.',
  blocks: [
    {
      title: 'Income Protection Illness Cover',
      description:
        'Can help cover everyday expenses like your mortgage, rates, power, food and other essential day to day living expenses if illness prevents you from working.',
    },
    {
      title: 'Serious Illness Trauma Cover',
      description:
        'Can provide a lump sum payment for you to use as you need during a critical health event.',
    },
  ],
  secondaryButton: {
    text: 'Request a call',
    href: urlConstants.lifeLivingInsuranceContactUs,
  },
  primaryButton: {
    text: 'Apply now',
    href: urlConstants.estimateYourCoverageLifeLivingInsurance,
  },
};

const frequentlyAskedQuestionsData: FAQ = {
  title: 'FAQs for Kiwibank Customers',
  description: <></>,
  showIcon: false,
  questions: [
    {
      title: 'Why do I need Life & Living Insurance if I have a mortgage?',
      content: (
        <>
          Life & Living Insurance can help protect your financial stability in case of the
          unexpected. If you become seriously ill or unable to work due to illness, this cover can
          help maintain your home and lifestyle. Life Cover can provide a lump sum payment if you
          pass away or are diagnosed with a terminal illness. To learn more about who will receive
          this payment, please refer to the{' '}
          <Link
            href={urlConstants.lifeAndLivingInsuranceFactSheet}
            icon={ExternalLinkSystemIcon}
            target="_blank"
          >
            Life and Living insurance fact sheet
          </Link>
          .
        </>
      ),
    },
    {
      title: 'What happens if I get sick or injured and can’t work?',
      content: (
        <>
          With Income Protection Illness Cover, you can receive monthly payments to help cover
          essential expenses like your mortgage, rates, power, food and other essential day to day
          living expenses. This can ensure that your financial obligations are met even if you’re
          unable to work due to illness.
        </>
      ),
    },
    {
      title: 'How can insurance help in a critical health event?',
      content: (
        <>
          Serious Illness Trauma Cover provides a lump sum payment if you’re diagnosed with a
          defined serious medical condition, such as cancer or a severe heart attack. You can use
          this payment however you choose—whether it’s covering medical bills, mortgage repayments,
          home modifications, or even taking a break to focus on recovery.
        </>
      ),
    },
    {
      title: 'What if my partner is the primary earner and they can’t work?',
      content: (
        <>
          If your partner is covered under a Life & Living policy, the insurance can help ensure
          mortgage payments and other essential expenses are taken care of if they become unable to
          work due to illness or a serious health condition, providing financial stability for your
          household.
        </>
      ),
    },
    {
      title: 'What if I’m not the primary earner in our household?',
      content: (
        <>
          Even if you’re not the main income earner - whether working or as a stay-at-home parent,
          your role is invaluable. Life insurance & Living Insurance can help cover household
          expenses or childcare costs if something happens to you, helping your family remain
          financially secure.
        </>
      ),
    },
    {
      title: 'Is this insurance different from traditional mortgage protection?',
      content: (
        <>
          Yes. Life insurance & Living insurance is designed to support various life events,
          including illness and death, with flexibility in how the payout is used to best support
          you and your loved ones.
        </>
      ),
    },
  ],
};

const navigation: NavContentProps = {
  title: 'Life & Living insurance',
  homeHref: urlConstants.compareLifeAndLivingInsurance,
  primaryButton: { text: 'Apply now', href: urlConstants.estimateYourCoverageLifeLivingInsurance },
  secondaryButton: { text: 'Contact us', href: urlConstants.lifeLivingInsuranceContactUs },
  navigation: navigationLinks,
};

const importantInformationData: ImportantInformationBannerProps = {
  title: 'Important Information',
  description: [
    <Stack space={4}>
      <Copy measure={false} size="small">
        Only the main features and benefits of the Life & Living Insurance is outlined here. For a
        full explanation of all benefits, exclusions and other terms and conditions of the cover,
        view the{' '}
        <Link href={urlConstants.lifeAndLivingInsuranceWording} icon={ExternalLinkSystemIcon}>
          Life & Living Insurance wording
        </Link>
        .
      </Copy>
      ,
      <Copy measure={false} size="small">
        Life & Living Insurance is provided by nib nz insurance limited and is a related company of
        nib nz limited (“nib”). nib nz insurance limited issues and underwrites Life & Living
        Insurance products, and is solely responsible for claims under the cover. nib sells and may
        advise you on these products. Find out more about{' '}
        <Link href={urlConstants.financialStrength}>
          financial strength and financial advice with nib
        </Link>
        .
      </Copy>
      <Heading component="h3" color="trueGreen" id="offer-terms">
        Get $300 Off Any Life & Living Insurance Policy - Terms and Conditions
      </Heading>
      <OrderedList>
        <ListItem measure={false}>
          <Copy measure={false} size="small">
            You (the policy owner) are eligible for this offer (Offer) if you submit an application
            for a new Life & Living Insurance product (Eligible Policy) between 1 March and 30 June
            2025 (or such later date as nib may permit in its absolute discretion) and your
            application for cover is accepted by nib.
          </Copy>
          <Copy measure={false} size="small">
            The Offer of $300 off will be applied as a credit on the Eligible Policy in the second
            month after your Eligible Policy is issued and after you’ve made the minimum premium
            payments:
          </Copy>
          <UnorderedList>
            <ListItem measure={false} size="small">
              If you pay weekly, the first four weekly premium payments need to be paid
            </ListItem>
            <ListItem measure={false} size="small">
              If you pay fortnightly, the first two fortnightly premium payments need to be paid
            </ListItem>
            <ListItem measure={false} size="small">
              If you pay by another frequency, the first premium payment needs to be paid
            </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem measure={false} size="small">
          The credit is not transferable, refundable or redeemable for cash.
        </ListItem>
        <ListItem measure={false} size="small">
          Maximum credit value of $300 per Eligible Policy, limited to one Eligible Policy for any
          one person insured during this promotional period, between 1 March and 30 June 2025.
        </ListItem>
        <ListItem measure={false} size="small">
          The credit will automatically go towards future premiums on the Eligible Policy and when
          the credit runs out, premiums will be payable at the applicable rate and the Eligible
          Policy will continue to be charged on the selected payment frequency. The credit may
          result in a partial payment being due, before payments return to the regular premium
          amount.
        </ListItem>
        <ListItem measure={false} size="small">
          The Offer cannot be used in conjunction with any other Life & Living Insurance offers.
        </ListItem>
        <ListItem measure={false} size="small">
          These terms and conditions may be updated or the Offer cancelled by nib provided this does
          not prejudice anyone who has already received the Offer. They were last updated on 20
          February 2025. nib will publish any change on our website{' '}
          <Link href="/">https://nib.co.nz</Link>.
        </ListItem>
        <ListItem measure={false} size="small">
          The Offer is promoted by nib nz insurance limited, 0800 555 642,{' '}
          <Link href="mailto:lifesales@nib.co.nz">lifesales@nib.co.nz</Link>. Level 9, 48 Shortland
          Street, Auckland City, 1010
        </ListItem>
      </OrderedList>
    </Stack>,
  ],
};

const lifeLivingSectionData: LifeLivingInsuranceSectionProps = {
  heroContent: contentHeroData,
  navigation: navigation,
  importantInformationData: importantInformationData,
};

interface InfoContentBlockProps {
  title: string;
  description: string;
}

interface FAQ {
  title: string;
  description: JSX.Element;
  showIcon: boolean;
  questions: { title: string; content: JSX.Element }[];
}

const LifeAndLivingKiwibank = (): JSX.Element => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': 'WebPage',
    identifier: 'https://www.nib.co.nz/life-cover-options/kiwibank',
    url: 'https://www.nib.co.nz/life-cover-options/kiwibank',
    description:
      'New Zealanders can get peace of mind if they pass away or can’t work anymore with life insurance or illness and income protection cover from nib.',
    name: ['Life & Living Insurance For Kiwibank Customers | nib'],
    isPartOf: 'https://www.nib.co.nz',
  };
  const JSONschema = JSON.stringify(schema);

  return (
    <Layout>
      <Helmet>
        <title>{title} | nib</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <LifeLivingInsuranceSection {...lifeLivingSectionData}>
        <MainContent />
      </LifeLivingInsuranceSection>
    </Layout>
  );
};

const MainContent = () => {
  return (
    <Section>
      <Stack space={{ xs: 5, xl: 8 }}>
        <ImageContentBlock />
        <InfoContentBlock />
        <FAQSection {...frequentlyAskedQuestionsData} />
      </Stack>
    </Section>
  );
};

const BorderBox = styled.div`
  border: 1px solid ${colorTrueGreen};
  border-radius: 20px;
  padding: 20px;
`;

const ImageContentBlock = () => {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', xxl: 'row-reverse' }}
      gap="4"
      padding="3"
      justifyContent="start"
    >
      <Box>
        <img src={contentData.image} alt={contentData.imageAlt} />
      </Box>
      <Stack space={4}>
        <Box display="flex" justifyContent={{ xs: 'center', xl: 'start' }}>
          <Heading color="trueGreen" size={{ xs: 2 }} component="h2">
            {contentData.title}
          </Heading>
        </Box>
        <Box display="flex" justifyContent="start">
          <Copy measure={false} color="deepBlack">
            {contentData.description}
          </Copy>
        </Box>
        <Box>
          <PrimaryButton href={contentData.buttonHref}>{contentData.buttonText}</PrimaryButton>
        </Box>
      </Stack>
    </Box>
  );
};

const InfoContentBlock = () => {
  return (
    <Box display="flex" flexDirection={{ xs: 'column', xxl: 'row-reverse' }} gap="4" padding="3">
      <Stack space={4}>
        <Box display="flex" justifyContent={{ xs: 'center', xl: 'start' }}>
          <Heading color="trueGreen" size={{ xs: 2 }} component="h2">
            {infoContentBlockData.title}
          </Heading>
        </Box>
        <Box display="flex" justifyContent="center">
          <Copy measure={false} color="deepBlack">
            {infoContentBlockData.description}
          </Copy>
        </Box>
        <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }} gap="4">
          {infoContentBlockData.blocks.map((box, index) => (
            <InfoDescriptionBlock key={index} title={box.title} description={box.description} />
          ))}
        </Box>
        <Box>
          <Bold>{infoContentBlockData.boldText}</Bold>
        </Box>
        <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }} gap={4}>
          <Box>
            <SecondaryButton href={infoContentBlockData.secondaryButton.href}>
              {infoContentBlockData.secondaryButton.text}
            </SecondaryButton>
          </Box>
          <Box>
            <PrimaryButton href={infoContentBlockData.primaryButton.href}>
              {infoContentBlockData.primaryButton.text}
            </PrimaryButton>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

const FAQSection = (faq: FAQ) => {
  return (
    <Stack space={4}>
      <Box display="flex" flexDirection="column" gap="4" padding="3" color="deepBlack">
        <Heading color="trueGreen" size={{ xs: 2 }} component="h2">
          {faq.title}
        </Heading>
        {faq.description}
      </Box>
      <Box>
        <Accordion borderTop={false} borderBottom multiExpanded={false}>
          {faq.questions.map((question, index) => (
            <Accordion.Item
              key={index}
              title={question.title}
              icon={faq.showIcon ? AddSystemIcon : undefined}
            >
              {question.content}
            </Accordion.Item>
          ))}
        </Accordion>
      </Box>
    </Stack>
  );
};

const InfoDescriptionBlock = ({ title, description }: InfoContentBlockProps) => {
  return (
    <BorderBox>
      <Stack>
        <Heading size={{ xs: 4 }} color="trueGreen" component="h3">
          {title}
        </Heading>
        <Copy>{description}</Copy>
      </Stack>
    </BorderBox>
  );
};

export default metrics({ pageName: 'life-and-living-apply' })(LifeAndLivingKiwibank);
